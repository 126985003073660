<template>
    <div style="display: flex">
        <el-select v-model="query.status" placeholder="状态" clearable style="width: 100px">
            <el-option v-for="(v, k) in image_status_enum" :label="v" :value="parseInt(k)"></el-option>
            <el-option label="待排序" :value="9"></el-option>
            <el-option label="已上线" :value="10"></el-option>
        </el-select>
        <el-select v-model="query.category_id" placeholder="分类" clearable style="width: 100px">
            <el-option v-for="c in category_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>
        </el-select>
        <el-select v-model="query.module" placeholder="模块" clearable style="width: 150px">
            <el-option v-for="(v, k) in image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="query.type" placeholder="类型" clearable style="width: 100px">
            <el-option v-for="(v, k) in image_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-if="!$root.part_time_mode" v-model="query.author" placeholder="作者" clearable style="width: 100px">
            <el-option v-for="a in author_list" :value="a"></el-option>
        </el-select>
        <el-select v-model="query.source" placeholder="来源" clearable style="width: 100px">
            <el-option value="CM"></el-option>
            <el-option value="CF"></el-option>
        </el-select>
        <el-date-picker v-model="query.finish_date" value-format="YYYY-MM-DD" type="daterange" clearable
                        start-placeholder="完成开始日期" end-placeholder="完成结束日期"></el-date-picker>
        <el-input v-model="query.nickname" style="width: 150px" placeholder="Nickname"></el-input>
        <el-input v-model="query.id" style="width: 200px" placeholder="图片ID"></el-input>
        <el-button type="primary" :loading="loading" @click="openDialog()">创建</el-button>
        <el-button v-if="!$root.part_time_mode" type="success" :loading="loading" @click="openDialog1()">导入</el-button>
        <el-button v-if="is_manager" type="danger" :loading="loading" @click="exportLibrary()">导出图库</el-button>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="dataPage">
        <el-table-column label="预览图" width="250">
            <template #default="scope">
                <el-image lazy :src="`${$root.cdn_url}/${scope.row.thumbnail}`" style="width: 100px"></el-image>
                <el-image lazy :src="`${$root.cdn_url}/${scope.row.finished}`" style="width: 100px"
                          v-if="scope.row.finished"></el-image>
                <div style="display: inline-block;width: 100px;height: 100px" v-loading="true" v-else></div>
            </template>
        </el-table-column>
        <el-table-column label="id" prop="id" width="150"></el-table-column>
        <el-table-column label="nick" prop="nickname" width="60"></el-table-column>
        <el-table-column label="模块" prop="module" width="100">
            <template #default="scope">
                {{ image_module_enum[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="110">
            <template #default="scope">
                <span style="color: var(--el-color-danger)" v-if="[2].includes(scope.row.status)">
                    {{ image_status_enum[scope.row.status] }}</span>
                <span style="color: var(--el-color-success)"
                      v-else-if="scope.row.status === 4 && scope.row.online_date <= timestampToDate(Date.now())">
                    已上线</span>
                <el-select v-model="scope.row.status" :disabled="loading"
                           v-else-if="[3, 4, 5].includes(scope.row.status)"
                           @change="update(scope.row)">
                    <el-option :value="3" label="测试中"></el-option>
                    <el-option :value="5" label="不通过"></el-option>
                    <el-option :value="4" label="通过"></el-option>
                </el-select>
                <span style="color: var(--el-color-info)" v-else>
                    {{ image_status_enum[scope.row.status] || '正在排队' }}</span>
                <span style="color: var(--el-color-warning)"
                      v-if="[3].includes(scope.row.status) && !scope.row.origin_mask1">
                    等待排序</span>
                <span style="color: var(--el-color-success)" v-if="scope.row.good">建议通过</span>
                <span style="color: var(--el-color-error)" v-else-if="scope.row.good===false">建议不通过</span>
                <el-progress v-if="scope.row.status === 1" :text-inside="true" :stroke-width="16"
                             :percentage="scope.row.progress">
                </el-progress>
            </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" width="60">
            <template #default="scope">
                {{ image_type_enum[scope.row.type] }}
            </template>
        </el-table-column>
        <el-table-column label="色块" prop="cells" width="60"></el-table-column>
        <el-table-column label="作者" prop="authors" width="80">
            <template #default="scope">
                {{ scope.row.authors.join(',') }}
            </template>
        </el-table-column>
        <el-table-column label="难度" prop="difficulty" width="60">
            <template #default="scope">
                <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                    L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="60">
            <template #default="scope">
                <template v-for="c in scope.row.categories">
                    <template v-for="cc in category_list">
                        <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="主要内容" prop="major_tags" width="100">
            <template #default="scope">
                <el-tag size="small" v-for="t in scope.row.major_tags">{{ t }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="次要内容" prop="minor_tags" width="100">
            <template #default="scope">
                <el-tag size="small" v-for="t in scope.row.minor_tags">{{ t }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="修改" width="60" prop="modify"></el-table-column>
        <el-table-column label="CM" width="60" prop="source_id"></el-table-column>
        <el-table-column label="完成时间" prop="finish_date" width="120"></el-table-column>
        <el-table-column label="上传时间" prop="c_time" width="120">
            <template #default="scope">
                {{ timestampToDate(scope.row.c_time) }}
            </template>
        </el-table-column>
        <el-table-column label="操作" width="450">
            <template #default="scope">
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" :disabled="loading" type="warning" @click="$refs.skeleton.init(scope.row)"
                           v-if="scope.row.status >= 3">线稿</el-button>
                <el-button size="small" :disabled="loading" type="success" @click="$refs.test.init(scope.row)"
                           v-if="scope.row.status >= 3">测试</el-button>
                <el-button size="small" :disabled="loading" @click="$refs.mask.init(scope.row)"
                           v-if="scope.row.status >= 3">顺序</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="reprocess(scope.row)"
                           v-if="scope.row.status >= 2 && reprocess_enabled">重新处理</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="margin-top: 10px;text-align: center;">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%" :close-on-click-modal="false">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="分类" prop="categories" :rules="[{required: true, message: '请选择分类'}]">
                <el-select v-model="form.categories" multiple style="width: 100%" filterable :disabled="loading">
                    <template v-for="c in category_list">
                        <el-option :value="c.id" :label="c.i18n_name.EN"
                                   v-if="!['BONUS', 'NEW'].includes(c.key)"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请选择分类'}]">
                <el-radio-group v-model="form.type" :disabled="loading">
                    <el-radio v-for="(v, k) in image_type_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="模块" prop="module" :rules="[{required: true, message: '请选择模块'}]">
                <el-radio-group v-model="form.module" :disabled="loading">
                    <el-radio v-for="(v, k) in image_module_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="主要标签" prop="major_tags" :rules="[{required: false, message: '请选择主要标签'}]">
                <el-tag v-for="t in form.major_tags" closable @close="removeTag('major_tags', t)">{{ t }}</el-tag>
                <el-popover trigger="click" v-if="form.major_tags.length < 1">
                    <el-cascader size="small" :options="content_tag" filterable v-model="major"
                                 :props="{expandTrigger: 'hover', label: 'name', value: 'name'}"
                                 @change="addTag($event, 'major_tags')"></el-cascader>
                    <template #reference>
                        <el-button size="small" text type="primary">添加
                        </el-button>
                    </template>
                </el-popover>
            </el-form-item>
            <el-form-item label="次要标签" prop="minor_tags" :rules="[{required: false, message: '请选择次要标签'}]">
                <el-tag v-for="t in form.minor_tags" closable @close="removeTag('minor_tags', t)">{{ t }}</el-tag>
                <el-popover trigger="click">
                    <el-cascader size="small" :options="content_tag" filterable v-model="minor"
                                 :props="{expandTrigger: 'hover', label: 'name', value: 'name'}"
                                 @change="addTag($event, 'minor_tags')"></el-cascader>
                    <template #reference>
                        <el-button size="small" text type="primary">添加
                        </el-button>
                    </template>
                </el-popover>
            </el-form-item>
            <el-form-item label="角标" prop="tags">
                <el-radio-group v-model="form.tag" :disabled="loading">
                    <el-radio :label="null">无</el-radio>
                    <el-radio :label="parseInt(k)" v-for="(v, k) in image_tag_enum">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <template v-if="dialog_opened1">
                <el-form-item label="导入ID" prop="image_id" :rules="[{required: true, message: '请输入图片id'}]">
                    <el-input-number v-model="form.image_id" :disabled="loading"></el-input-number>
                </el-form-item>
            </template>
            <template v-else>
                <el-form-item v-if="!$root.part_time_mode" label="作者" prop="authors" :rules="[{required: true, message: '请选择作者'}]">
                    <el-select v-model="form.authors" multiple allow-create filterable style="width: 100%">
                        <el-option v-for="a in author_list" :value="a"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="完成日期" prop="finish_date" :rules="[{required: true, message: '请选择完成日期'}]">
                    <el-date-picker v-model="form.finish_date" value-format="YYYY-MM-DD"></el-date-picker>
                </el-form-item>
                <el-form-item label="背景图" prop="origin_back" :rules="[{required: true, message: '请上传背景图'}]">
                    <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleFinish"
                               accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                               :disabled="editing && [1].includes(editing.status)">
                        <img v-if="form.origin_back" :src="`${$root.cdn_url}/${form.origin_back}`"
                             style="width: 200px">
                        <el-button size="small" v-else :loading="loading">上传</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="前景图" prop="origin_fore" :rules="[{required: true, message: '请上传前景图'}]"
                              v-if="form.type !== 3">
                    <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSvg"
                               accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                               :disabled="editing && [1].includes(editing.status)">
                        <img v-if="form.origin_fore" :src="`${$root.cdn_url}/${form.origin_fore}`"
                             style="width: 200px">
                        <el-button size="small" v-else :loading="loading">上传</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="Mask" prop="origin_mask" :rules="[{required: true, message: '请上传mask'}]"
                              v-if="form.type === 2">
                    <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleMask"
                               accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                               :disabled="editing && [1].includes(editing.status)">
                        <img v-if="form.origin_mask" :src="`${$root.cdn_url}/${form.origin_mask}`"
                             style="width: 200px">
                        <el-button size="small" v-else :loading="loading">上传</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="缩略图" prop="origin_thumb" :rules="[{required: true, message: '请上传缩略图'}]"
                              v-if="form.type !== 3">
                    <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumb"
                               accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress">
                        <img :src="`${$root.cdn_url}/${form.origin_thumb}`"
                             style="width: 200px" v-if="form.origin_thumb">
                        <el-button size="small" v-else :loading="loading">上传</el-button>
                    </el-upload>
                </el-form-item>
            </template>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <Test ref="test"></Test>
    <Mask ref="mask"></Mask>
    <Skeleton ref="skeleton"></Skeleton>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, update} from "../libs/utils";
import Test from "./Test";
import Mask from './Mask';
import Skeleton from "./Skeleton";
import {has_feature_reprocess, is_manager} from "../libs/permission";
import {ElMessageBox} from "element-plus";
import {is_part_time_mode} from "../libs/app";
import {root_props as $root} from "../main";

export default {
    name: 'Index',
    components: {Skeleton, Test, Mask},
    data() {
        return {
            loading: false, data: [], category_list: [], page: 1, size: 50, author_list: [], dialog_opened: false,
            editing: null, content_tag: [], style_tag: [], total: 0, major: null, minor: null, style: null,
            reprocess_enabled: has_feature_reprocess(),
            image_status_enum: {1: '处理中', 2: '错误', 5: '未通过', 3: '测试中', 4: '通过'}, dialog_opened1: false,
            image_module_enum: {1: '图库', 2: 'DAILY', 3: 'Bonus', 4: 'DailyChallenge', 5 : 'Collection'}, history: [], history_list: [],
            image_tag_enum: {
                1: 'Special',
            },
            image_type_enum: {1: '单色', 2: '彩绘', 3: '单色问号'}, timeout_list: [],
            query: {
                status: null,
                id: null,
                nickname: null,
                module: null,
                category_id: null,
                finish_date: null,
                author: null,
                type: null,
                difficulty: null,
                source: null,
            },
            form: this.createForm(),
            image_url: is_part_time_mode() ? "/cms/v1/part-time/image" : "/cms/v1/image",
        }
    },
    methods: {
      is_manager,
        init() {
            this.loading = true;
            axios.all([
                axios.get(`${this.image_url}`).then(res => {
                    this.data = res.data.data.imageList;
                    this.total = this.data.length;
                    this.author_list = [];
                    this.data.forEach(d => {
                        d.authors.forEach(a => {
                            if (!this.author_list.includes(a)) {
                                this.author_list.push(a);
                            }
                        })
                        this.refreshOne(d);
                    })
                }),
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
                axios.get(`/cms/v1/tag/content`).then(res => {
                    this.content_tag = res.data.data.tagList;
                }),
                axios.get(`/cms/v1/history/image`).then(res => {
                    this.history = res.data.data.countList;
                }),
            ]).then(_ => {
                this.data.forEach(d => {
                    this.history.forEach(h => {
                        if (d.id === h.image_id) {
                            d.modify = h.count;
                        }
                    })
                })
                this.loading = false;
            })
        },
        refreshOne(row) {
            if (row.status === null) {
                this.timeout_list.push(setTimeout(_ => {
                    axios.get(`${this.image_url}/${row.id}`).then(res => {
                        update(row, res.data.data);
                        this.refreshOne(row);
                    });
                }, 10 + Math.random() * 10000));
            } else if (row.status === 1) {
                this.timeout_list.push(setTimeout(_ => {
                    axios.get(`${this.image_url}/${row.id}`).then(res => {
                        update(row, res.data.data);
                        this.refreshOne(row);
                    });
                }, 3000));
            }
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        openDialog1() {
            this.dialog_opened = this.dialog_opened1 = true;
        },
        exportLibrary() {
          if (this.data != null) {
            const images = []
            const category_map = {}
            this.category_list.forEach(c => {
              category_map[c.id] = c.i18n_name.EN
            })
            this.data.forEach(i => {
              const image = {}
              image.nickname = i.nickname
              if (i.categories != null) {
                image.category = category_map[i.categories[0]]
                image.origin_back = i.origin_back != null ? $root.cdn_url + '/' + i.origin_back : null
                image.origin_fore = i.origin_fore != null ? $root.cdn_url + '/' + i.origin_fore : null
                image.finished = i.finished != null ? $root.cdn_url + '/' + i.finished : null
                images.push(image)
              }
            })
            const jsonData = JSON.stringify(images, null, 2);
            const blob = new Blob([jsonData], {type: 'application/json'});
            const tempLink = document.createElement('a');
            tempLink.setAttribute('href', URL.createObjectURL(blob));
            tempLink.setAttribute('download', 'library.json');
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
          }
        },
        reset() {
            this.$refs.form.resetFields();
            this.form = this.createForm()
            this.editing = null;
            this.major = this.minor = this.style = null;
            this.dialog_opened = this.dialog_opened1 = this.loading = false;
        },
        createForm() {
          return {
            authors: [],
            categories: [],
            difficulty: null,
            major_tags: [],
            minor_tags: [],
            style_tags: [],
            module: null,
            tag: null,
            type: 1,
            video: null,
            finish_date: null,
            online_date: null,
            origin_thumb: null,
            origin_back: null,
            origin_mask: null,
            origin_fore: null,
            status: null,
            image_id: null,
          }
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.dialog_opened1) {
                        axios.post(`cms/v1/import`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.refreshOne(res.data.data);
                            this.reset();
                        })
                    } else if (this.editing) {
                        axios.put(`${this.image_url}/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.refreshOne(this.editing);
                            this.reset();
                        })
                    } else {
                      axios.post(`${this.image_url}`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.refreshOne(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`${this.image_url}/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        removeTag(category, tag) {
            this.form[category].splice(this.form[category].indexOf(tag), 1);
        },
        addTag(value, category) {
            this.form[category].push(value[value.length - 1]);
        },
        handleFinish(res) {
            this.loading = false;
            this.form.origin_back = res.data.name
        },
        handleSvg(res) {
            this.loading = false;
            this.form.origin_fore = res.data.name
        },
        handleThumb(res) {
            this.loading = false;
            this.form.origin_thumb = res.data.name
        },
        handleMask(res) {
            this.loading = false;
            this.form.origin_mask = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        reprocess(row) {
            ElMessageBox.confirm('重新处理会导致排序丢失，确定要执行操作吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: "warning",
            }).then(_ => {
                this.loading = true;
                axios.post(`/cms/v1/image/${row.id}/reprocess`).then(res => {
                  row.status = null;
                  this.refreshOne(row);
                  this.loading = false;
                });
            }).catch(_ => {})
        },
    },
    mounted() {
        this.init();
    },
    beforeUnmount() {
        this.timeout_list.forEach(t => {
            clearTimeout(t);
        });
    },
    computed: {
        dataFilter() {
            const now = timestampToDate(Date.now());
            return this.data.filter(d => {
                return (!this.query.status || d.status === this.query.status && this.query.status !== 3 && (!d.online_date || d.online_date > now) || this.query.status === 10 && d.online_date && d.online_date < now || this.query.status === 9 && d.status === 3 && !d.origin_mask1 || this.query.status === 3 && d.status === 3 && d.origin_mask1)
                    && (!this.query.nickname || (d.nickname + '').includes(this.query.nickname))
                    && (!this.query.id || (d.id).includes(this.query.id))
                    && (!this.query.author || d.authors.includes(this.query.author))
                    && (!this.query.type || d.type === this.query.type)
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (!this.query.module && this.query.module !== 0 || d.module === this.query.module)
                    && (!this.query.finish_date || d.finish_date >= this.query.finish_date[0] & d.finish_date <= this.query.finish_date[1])
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (!this.query.source || this.query.source === 'CM' && d.source_id || this.query.source === 'CF' && !d.source_id)
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        }
    }
}
</script>

<style scoped>

</style>